import { ActionContext } from 'vuex'
import axiosApi from "@/services/axiosApi"
import {CategoriesState, Category} from "@/interfaces/CategoryInterface"

export const categories = {
    namespaced: true,

    // State
    state: (): CategoriesState => ({
        categories: []
    }),

    // Getters
    getters: {
        GET_CATEGORIES(state: CategoriesState): Category[] {
            return state.categories;
        }
    },

    // Mutations
    mutations: {
        SET_CATEGORIES(state: CategoriesState, categories: Category[]): void {
            state.categories = categories;
        }
    },

    // Actions
    actions: {
        async fetchCategories({ commit }: ActionContext<CategoriesState, any>, { id, lang }: {id: string, lang: string}): Promise<void> {
            axiosApi.defaults.headers.common['Accept-Language'] = lang;
           try {
               const response = await axiosApi.get(`admin/categories/${id}`);
               commit('SET_CATEGORIES', response.data.data);
           }catch (e: any) {
               console.error('Error fetching categories:', e.messages)
           }
        }
    }
};
