import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { defineAsyncComponent } from 'vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: defineAsyncComponent(() => import('@/views/LoginView.vue'))
  },
  {
    path: '/',
    name: 'home',
    component: defineAsyncComponent(() => import('@/views/HomeView.vue'))
  },
  {
    path: '/chat',
    name: 'chat',
    component: defineAsyncComponent(() => import('@/views/ChatView.vue'))
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: defineAsyncComponent(() => import('@/views/ReviewView.vue'))
  },
  {
    path: '/settings',
    name: 'settings',
    component: defineAsyncComponent(() => import('@/views/SettingsView.vue'))
  },
  {
    path: '/companies',
    name: 'companies',
    component: defineAsyncComponent(() => import('@/views/companies/MainCompanies.vue')),
  },
  {
    path: '/companies/create',
    name: 'create.company',
    component: defineAsyncComponent(() => import('@/views/companies/create/AddCompany.vue'))
  },
  {
    path: '/companies/:id',
    name: 'companies.details',
    component: defineAsyncComponent(() => import('@/views/companies/ShowDetails.vue')),
  },
  {
    path: '/companies/:id/facilities',
    name: 'update.facilities',
    component: defineAsyncComponent(() => import('@/views/companies/facilities/UpdateFacilityComponent.vue')),
  },
  {
    path: '/companies/:id/banner-edit',
    name: 'banner.edit',
    component: defineAsyncComponent(() => import('@/views/companies/banner/EditBanner.vue')),
  },
  {
    path: '/companies/:id/services',
    name: 'show.services',
    component: defineAsyncComponent(() => import('@/views/companies/services/ShowComponent.vue')),
  },
  {
    path: '/companies/:id/categories',
    name: 'show.categories',
    component: defineAsyncComponent(() => import('@/views/companies/categories/ShowCategories.vue')),
  },
  {
    path: '/all-orders/:page?/:date_from?/:date_to?',
    name: 'all.orders',
    component: defineAsyncComponent(() => import('@/views/orders/AllOrdersView.vue'))
  },
  {
    path: '/pending-orders/:page?/:date_from?/:date_to?',
    name: 'pending.orders',
    component: defineAsyncComponent(() => import('@/views/orders/PendingOrdersView.vue'))
  },
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token_access_token')
  if(token && to.name === 'login') {
    next({name: 'home'})
  } else if (token || to.name === 'login') {
    next()
  } else {
    next({name: 'login'})
  }
});

export default router
